import { Button, Row, Typography } from "antd";
import React, { useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import UserDetail from "../../utils/UserDetail";
import { CheckOutlined, CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import ChatLoader from "../../assets/loader.gif";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { downloadDoc } from "../../app/services/DocumentServices";
import useLogoutCurrentUser from "../../utils/logoutCurrentUser";

const ChatScreen = (props: any) => {
	const [copiedIndex, setCopiedIndex] = useState<number>(-1);
	const { first_name } = UserDetail();
	const logoutCurrentUser = useLogoutCurrentUser();

	const handleCopy = (msg: string, index: number) => {
		navigator.clipboard.writeText(msg);
		setCopiedIndex(index);

		setTimeout(() => {
			setCopiedIndex(-1);
		}, 1000);

		downloadDoc(msg)
			.then((response) => {
				const blob = new Blob([response.data], { type: "application/pdf" });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'doc.pdf');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			})
			.catch((error) => {
				if(error.response?.status === 401){
					logoutCurrentUser();
				}
				console.log(error)
			});
	};
	// console.log(props.messages);

	return (
		<>
			<ScrollToBottom
				className={`w-full bg-[#f1efef] overflow-y-auto flex-1 overflow-hidden chat-container dark:bg-darkBg`}
			>
				{props.messages.map((message: any, index: number) => (
					<div className={`chat-row `} key={`message-${index}`}>
						<div className="shadow-md">
							<div className="container mx-auto p-10 flex">
								<Typography className="bg-green-600 text-white w-9 h-9 flex justify-center items-center text-2xl mr-4">
									{first_name
										? first_name.charAt(0).toUpperCase()
										: ""}
								</Typography>
								<Typography
									style={{ whiteSpace: "pre-line" }}
									className="text-base font-medium leading-8 flex-1"
								>									
									{message.question}
								</Typography>
							</div>
						</div>
						<div className="bg-gray-100 dark:bg-darkCard">
							<div className="container mx-auto p-10 flex">
								<Typography className="bg-primary text-white w-9 h-9 flex justify-center items-center text-2xl mr-4">
									L
								</Typography>
								<Typography className="text-base leading-8 w-[calc(100%-3.1rem)] opacity-70 dark:opacity-100" id="draft_doc">
									{props.loading &&
									props.loadingQuestion ===
										message.question ? (
										<img
											alt="chat-loader"
											src={ChatLoader}
											className={`${"h-[40px] lg:h-[60px]"}`}
										/>
									) : (
										""
									)}
									<Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>{message.text}</Markdown>	
								</Typography>
								<Row>
									<Button
										onClick={() =>
											handleCopy(message.text, index)
										}
									>
										{copiedIndex === index ? (
											<CheckOutlined />
										) : (
											<DownloadOutlined />
										)}
									</Button>
								</Row>
							</div>
						</div>
					</div>
				))}
			</ScrollToBottom>
		</>
	);
};

export default ChatScreen;
