import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { registerUser } from "../../app/services/UserServices";
import { RootState } from "../../app/store/store";
import Logo from "../../components/Common/Logo";
import APP_CONSTANTS from "../../utils/constants/app.constants";
import { RegisterData } from "./RegisterType";
import { countries } from "country-data";
type Props = {};

const Registration = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState<any>({});
  const [success, setSuccess] = useState<boolean>(false);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [size] = useState<SizeType>("large");
  const [selectedCountryCode, setSelectedCountryCode] = useState<string | undefined>(undefined);
  const [countryCodeOptions, setCountryCodeOptions] = useState<{ value: string; label: string }[]>([]);

	useEffect(() => {
		const data = Object.keys(countries)
			.filter((countryCode) => {
				const country = countries[countryCode];
				return country && country.countryCallingCodes && country.countryCallingCodes.length > 0;
			})
			.filter((_, index) => index % 2 === 1)
			.map((countryCode) => ({
			value: countries[countryCode].countryCallingCodes[0],
			label: `${countries[countryCode].countryCallingCodes} (${countries[countryCode].name})`,
		}));

		setCountryCodeOptions(data);
	}, []);

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (values: any) => {
	setLoading(true);
    const register: RegisterData = {
      first_name: values.firstname,
	  last_name: values.lastname,
	  phone_number: selectedCountryCode + values.phone,
      date_of_birth: moment(values.dateofbirth).format("YYYY-MM-DD"),
      email: values.email,
      password: values.password,
	};
    registerUser(register).then((res: any) => {
      if (res.status === 201) {
        setError("");
        setSuccess(true);
        setLoading(false);
        form.resetFields();
        console.log("Success response : ", res.data);
      } else {
        setError(res);
        setLoading(false);
        console.log("Error response : ", res);
      }
    });
  };

  return (
		<Row
			justify="center"
			className="min-h-[calc(100vh-120px)] flex flex-col justify-center gap-4 items-center lg:mb-0 mb-5 dark:bg-darkBg"
		>
			<Row className="text-center">
				<Logo />
			</Row>
			<Col lg={10} xs={22}>
				<Card title="Sign Up" className="card-title-center ">
					{errors.non_field_errors &&
						errors.non_field_errors.map(
							(error: any, index: any) => (
								<Alert
									message={error}
									type="error"
									showIcon
									className="mb-3"
								/>
							),
						)}
					{success && (
						<Alert
							message={APP_CONSTANTS.AUTH_SUCCESS.REGISTER}
							type="success"
							closable
							showIcon
							className="mb-3"
						/>
					)}
					<Form
						form={form}
						name="register"
						layout="vertical"
						initialValues={{ remember: true }}
						onFinish={handleSubmit}
						autoComplete="off"
					>
						<Form.Item className="mb-0">
							<Form.Item
								name="firstname"
								label="First name"
								help={errors.first_name}
								validateStatus={
									errors.first_name ? "error" : ""
								}
								rules={[
									{
										required: true,
										message:
											APP_CONSTANTS.AUTH_ERROR.FIRST_NAME,
									},
								]}
								className="lg:inline-block lg:w-[calc(50%-8px)]"
							>
								<Input
									placeholder="First name"
									prefix={
										<UserOutlined className="site-form-item-icon" />
									}
								/>
							</Form.Item>
							<Form.Item
								name="lastname"
								label="Last name"
								help={errors.last_name}
								validateStatus={errors.last_name ? "error" : ""}
								rules={[
									{
										required: true,
										message:
											APP_CONSTANTS.AUTH_ERROR.LAST_NAME,
									},
								]}
								className="lg:inline-block lg:w-[calc(50%-8px)] lg:mx-2 lg:my-0"
							>
								<Input
									placeholder="Last name"
									prefix={
										<UserOutlined className="site-form-item-icon" />
									}
								/>
							</Form.Item>
						</Form.Item>
					  <Form.Item className="mb-0">
						  <Form.Item
							name="countryCode"
							label="Country Code"
							help={errors.country_code}
							validateStatus={
								errors.country_code ? "error" : ""
							}
							rules={[
								{
									required: true,
									message:
										APP_CONSTANTS.AUTH_ERROR
											.COUNTRY_CODE,
								},
							]}
							className="lg:inline-block lg:w-[calc(50%-8px)]">
							<Select
								showSearch
								filterOption={(input, option) =>
									(option?.label.toLowerCase() || '').indexOf(input.toLowerCase()) >= 0
								}
								options={countryCodeOptions}
								value={selectedCountryCode}
								onChange={(value) => setSelectedCountryCode(value)}
								placeholder="Select Country Code"
							/>
						</Form.Item>
						<Form.Item
							name="phone"
							label="Phone number"
							help={errors.phone_number}
							validateStatus={
								errors.phone_number ? "error" : ""
							}
							rules={[
								{
									required: true,
									message:
										APP_CONSTANTS.AUTH_ERROR
											.PHONE_NUMBER,
								},
							]}
							className="lg:inline-block lg:w-[calc(50%-8px)] lg:mx-2 lg:my-0"
						>
							<Input
								placeholder="Phone number"
								prefix={
									<PhoneOutlined className="site-form-item-icon" />
								}
							/>
						</Form.Item>
					  </Form.Item>

					  <Form.Item className="mb-0">
					  	<Form.Item
						  name="dateofbirth"
						  label="Date of birth"
						  help={errors.date_of_birth}
						  validateStatus={
							  errors.date_of_birth ? "error" : ""
						  }
						  className="lg:inline-block lg:w-[calc(50%-8px)] lg:my-0"
						  rules={[
							  {
								  required: true,
								  message:
									  APP_CONSTANTS.AUTH_ERROR.BIRTH_DATE,
							  },
						  ]}
					  >
						  <DatePicker
							  showTime={false}
							  className="block"
							  placeholder="Date of birth"
						  />
						  </Form.Item>
						  <Form.Item
							  name="email"
							  label="Email"
							  help={errors.email}
							  validateStatus={errors.email ? "error" : ""}
							  className="lg:inline-block lg:w-[calc(50%-8px)] lg:mx-2"
							  rules={[
								  {
									  required: true,
									  message: APP_CONSTANTS.AUTH_ERROR.EMAIL,
								  },
							  ]}
						  >
							  <Input
								  placeholder="Email"
								  prefix={
									  <MailOutlined className="site-form-item-icon" />
								  }
							  />
						  </Form.Item>
					  </Form.Item>

						<Form.Item className="mb-0">
							<Form.Item
								name="password"
								label="Password"
								help={errors.password}
								validateStatus={errors.password ? "error" : ""}
								className="lg:inline-block lg:w-[calc(50%-8px)]"
								rules={[
									{
										required: true,
										message:
											APP_CONSTANTS.AUTH_ERROR.PASSWORD,
									},
								]}
							>
								<Input.Password
									placeholder="Password"
									prefix={
										<LockOutlined className="site-form-item-icon" />
									}
								/>
							</Form.Item>
						</Form.Item>
						<Form.Item className="mb-0 text-center">
							<Button
								type="primary"
								loading={loading}
								htmlType="submit"
								size={size}
								className="text-white bg-primary hover:bg-white hover:text-primary !px-12"
							>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Col>
		</Row>
  );
};

export default Registration