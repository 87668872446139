import { LockOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Input, Row, Form } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../../app/services/UserServices";
import { RootState } from "../../app/store/store";
import Logo from "../../components/Common/Logo";
import APP_CONSTANTS from "../../utils/constants/app.constants";
import { ResetPasswordData } from "./ResetPasswordType";

type Props = {};

const ResetPassword = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState<any>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [size] = useState<SizeType>("large");

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (values: any) => {
    setLoading(true);
    const data: ResetPasswordData = {
      password: values.password,
      email: searchParams.get("value"),
      session_token: searchParams.get("token"),
      security_code: searchParams.get("token"),
    };
    if (values.password !== values.confirm_password) {
      setError({ password: [APP_CONSTANTS.AUTH_ERROR.RESET_PASSWORD] });
      setLoading(false);
    } else {
      resetPassword(data).then((res: any) => {
        if (res.status === 202) {
          setError("");
          setSuccess(true);
          setLoading(false);
          form.resetFields();
          navigate("/user/login");
        } else {
          setError(res);
          setLoading(false);
          console.log("failed");
        }
      });
    }
  };
  return (
    <Row
      justify="center"
      className="min-h-[calc(100vh-120px)] flex flex-col justify-center gap-4 items-center"
    >
      <Row className="text-center">
        <Logo />
      </Row>
      <Col md={10} xs={22}>
        <Card title="Reset Password" className="card-title-center">
          {success && (
            <Alert
              message={APP_CONSTANTS.AUTH_SUCCESS.RESET_PASSWORD}
              type="success"
              closable
              showIcon
              className="mb-3"
            />
          )}
          {errors.password &&
            errors.password.map((error: any, index: any) => (
              <Alert message={error} type="error" showIcon className="mb-3" />
            ))}
          {errors.non_field_errors &&
            errors.non_field_errors.map((error: any, index: any) => (
              <Alert message={error} type="error" showIcon className="mb-3" />
            ))}
          <Form
            form={form}
            name="register"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: APP_CONSTANTS.AUTH_ERROR.PASSWORD },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              label="Confirm password"
              name="confirm_password"
              rules={[
                { required: true, message: APP_CONSTANTS.AUTH_ERROR.PASSWORD },
              ]}
            >
              <Input.Password
                placeholder="Confirm password"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item className="mb-0 text-center">
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                size={size}
                className="text-white bg-primary hover:bg-white hover:text-primary !px-8"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPassword;
