import { useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "antd";
import type { SizeType } from "antd/es/config-provider/SizeContext";

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
];

export default function Hero() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [size] = useState<SizeType>("large");

  return (
    <div className="dark:bg-darkBg">
      <div className="relative isolate px-6 pt-14 lg:px-8 flex justify-center items-center h-[95vh]">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="sm:max-w-3xl max-w-xl">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl dark:text-white">
              Chat with our Rondesse AI and unlock the truth!!!
            </h1>

            <div className="mt-20 flex items-center justify-center gap-x-6 ">
              <Link to={"/features"}>
                <Button type="primary" className="!px-8" size={size}>
                  Get Started
                </Button>
              </Link>
              {/* <Link to={"/chat"}>
								<Button
									className="text-white bg-primary hover:bg-white hover:text-primary !px-8"
									size={size}
								>
									Chat Bot
								</Button>
							</Link>
							<Link to={"/document-generation"}>
								<Button
									className="text-white bg-primary hover:bg-white hover:text-primary !px-8"
									size={size}
								>
									Document Generation
								</Button>
							</Link> */}
              {/* <a
								href="#"
								className="text-sm font-semibold leading-6 text-gray-900"
							>
								Learn more <span aria-hidden="true">→</span>
							</a> */}
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] hidden"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
