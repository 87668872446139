import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { FileSearchOutlined } from "@ant-design/icons";
import { Layout, Row } from "antd";
import Typography from "antd/es/typography/Typography";
import { SearchType } from "./SearchType";
import { searchApiNew, searchHistory } from "../../app/services/SearchServices";
import SideBar from "./SideBar";
import ChatBar from "./ChatBar";
import UserDetail from "../../utils/UserDetail";
import ChatScreen from "./ChatScreen";
import {
	docApiNew,
	documentHistory,
    docChatHistoryApi,
} from "../../app/services/DocumentServices";
import { chatHistoryApi } from "../../app/services/SearchServices";
import useLogoutCurrentUser from "../../utils/logoutCurrentUser";

const { Content } = Layout;

const Entry = (props: any) => {
	//For document generation
	const type = props.type === "doc" ? "doc" : "";
	const location = useLocation();
    const { sessionId } = useParams();
	const [formData, setFormData] = useState<{ query: string }>({ query: "" });
	const [error, setError] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [submitBtn, setSubmitBtn] = useState(false);
	const queryRef: any = useRef<HTMLInputElement>();
	const [newChat, setNewChat] = useState(false);
    const navigate = useNavigate();
	const logoutCurrentUser = useLogoutCurrentUser();
	// const [sessionId, setSessionId] = useState(sessionIdFromParams);
	const [messages, setMessages] = useState<
		{
			type: string;
			text: string;
			question: string;
		}[]
	>([]);
	const [chatHistory, setchatHistory] = useState<
		{
			label: string;
			entries: {
				label: string;
				url: string;
			}[];
		}[]
	>([
		{
			label: "",
			entries: [
				{
					label: "",
					url: "",
				},
			],
		},
	]);
	const { first_name } = UserDetail();
	const [loadingChat, setLoadingChat] = useState(false);
	const [loadingQuestion, setLoadingQuestion] = useState("");

	useEffect(() => {
		if (messages.length > 0) {
			setNewChat(false);
		} else {
			setNewChat(true);
		}
	}, [messages]);

    useEffect(() => {
		if (type === "doc") {
			docChatHistoryApi(sessionId)
				.then((res: any) => {
					setMessages(res.data.chat);
				})
				.catch((err) => {
					if(err.response?.status === 401){
						logoutCurrentUser();
					}
					console.log(err);
				});
		} else {
			setTimeout(()=>{
				chatHistoryApi(sessionId)
				.then((res: any) => {
					// console.log(res.data.menuItems);
					setMessages(res.data.chat);
				})
				.catch((error) => {
					if(error.response?.status === 401){
						logoutCurrentUser();
					}
					console.log(error);
				});
			}, 10)
		}
	}, [sessionId]);

	useEffect(() => {
		setchatHistory([
			{
				label: "",
				entries: [
					{
						label: "",
						url: "",
					},
				],
			},
		]);
		if (type === "doc") {
			documentHistory()
				.then((res: any) => {
					setchatHistory(res.data.menuItems);
				})
				.catch((err) => {
					if(err.response?.status === 401){
						logoutCurrentUser();
					}
					console.log(err);
				});
		} else {
			searchHistory()
				.then((res: any) => {
					// console.log(res.data.menuItems);
					setchatHistory(res.data.menuItems);
				})
				.catch((error) => {
					if(error.response.status===401){
						logoutCurrentUser();
					}
					console.log(error);
				});
		}
	}, [location]);

	const findLastIndexOfMessage = (arr:any, predicate:any) => {
		for (let i = arr.length - 1; i >= 0; i--) {
		  if (predicate(arr[i])) {
			return i;
		  }
		}
		return -1; // Return -1 if no match is found
	  };

	useEffect(() => {
		console.log(messages, "test")
		if (
			messages.length > 0 &&
			messages[messages.length - 1].type === "sent"
		) {
			const data: SearchType = {
				query: messages[messages.length - 1]["question"],
				session: sessionId,
			};

			setError((prev) => false);
			setIsDisabled(true);
			setLoadingChat(true);
			setLoadingQuestion(messages[messages.length - 1]["question"]);
			if (type === "doc") {
				docApiNew(data)
					.then((res: any) => {
						console.log(res);
						let newMessage = {
							type: "received",
							text: res.data.result,
							question: messages[messages.length - 1]["question"],
						};

						// Find the index of the matching question in the messages array
						const matchingQuestionIndex = findLastIndexOfMessage(
							messages,
							(message:any) =>
								message.question === newMessage.question,
						);

						// // If a matching question is found, update the existing message
						if (matchingQuestionIndex !== -1) {
							setMessages((prevMessages) => {
								const updatedMessages = [...prevMessages];
								updatedMessages[matchingQuestionIndex] =
									newMessage;
								return updatedMessages;
							});
						} else {
							// If no matching question is found, add the new message to the array
							setMessages((prevMessages) => [
								...prevMessages,
								newMessage,
							]);
						}

						setIsDisabled(false);
						if (queryRef.current) {
							queryRef.current.focus();
						}
						setLoadingChat(false);
						setLoadingQuestion("");
					})
					.catch((error) => {
						console.log(error);
						if(error.response?.status === 401){
							logoutCurrentUser();
						}
						let newMessage = {
							type: "error",
							text: "Getting some issue to find the answer please try again..",
							question: messages[messages.length - 1]["question"],
						};

						// Find the index of the matching question in the messages array
						const matchingQuestionIndex = findLastIndexOfMessage(
							messages,
							(message:any) =>
								message.question === newMessage.question,
						);

						// If a matching question is found, update the existing message
						if (matchingQuestionIndex !== -1) {
							setMessages((prevMessages) => {
								const updatedMessages = [...prevMessages];
								updatedMessages[matchingQuestionIndex] =
									newMessage;
								return updatedMessages;
							});
						} else {
							// If no matching question is found, add the new message to the array
							setMessages((prevMessages) => [
								...prevMessages,
								newMessage,
							]);
						}

						setIsDisabled(false);
						if (queryRef.current) {
							queryRef.current.focus();
						}
						setLoadingChat(false);
						setLoadingQuestion("");
					});
			} else {
				searchApiNew(data)
					.then((res: any) => {
						let newMessage = {
							type: "received",
							text: res.data.result,
							question: messages[messages.length - 1]["question"],
						};

						// Find the index of the matching question in the messages array
						const matchingQuestionIndex = findLastIndexOfMessage(
							messages,
							(message:any) =>
								message.question === newMessage.question,
						);

						// If a matching question is found, update the existing message
						if (matchingQuestionIndex !== -1) {
							setMessages((prevMessages) => {
								const updatedMessages = [...prevMessages];
								updatedMessages[matchingQuestionIndex] =
									newMessage;
								return updatedMessages;
							});
						} else {
							// If no matching question is found, add the new message to the array
							setMessages((prevMessages) => [
								...prevMessages,
								newMessage,
							]);
						}

						setIsDisabled(false);
						if (queryRef.current) {
							queryRef.current.focus();
						}
						setLoadingChat(false);
						setLoadingQuestion("");
					})
					.catch((error) => {
						console.log(error);
						if(error.response.status===401){
							logoutCurrentUser();
						}
						let newMessage = {
							type: "error",
							text: "Getting some issue to find the answer please try again..",
							question: messages[messages.length - 1]["question"],
						};

						// Find the index of the matching question in the messages array
						const matchingQuestionIndex = findLastIndexOfMessage(
							messages,
							(message:any) =>
								message.question === newMessage.question,
						);

						// If a matching question is found, update the existing message
						if (matchingQuestionIndex !== -1) {
							setMessages((prevMessages) => {
								const updatedMessages = [...prevMessages];
								updatedMessages[matchingQuestionIndex] =
									newMessage;
								return updatedMessages;
							});
						} else {
							// If no matching question is found, add the new message to the array
							setMessages((prevMessages) => [
								...prevMessages,
								newMessage,
							]);
						}

						setIsDisabled(false);
						if (queryRef.current) {
							queryRef.current.focus();
						}
						setLoadingChat(false);
						setLoadingQuestion("");
					});
			}
		}
	}, [messages, sessionId]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		// let Query = formData.query.replace(/\n/g, "<br>");
		// console.log(Query);

		if (formData["query"] !== "") {
			setError((prev) => false);
			setIsDisabled(true);
			setSubmitBtn(false);
			let newMessage = {
				type: "sent",
				text: "",
				question: formData["query"],
			};
			setMessages((prevMessages) => [...prevMessages, newMessage]);

			// update the chatHistory useState
			if (chatHistory.length > 0 && messages.length < 1) {
				const newChat = {
					label: formData["query"],
					url:
						`${
							type === "doc" ? "/document-generation/" : "/chat/"
						}` + sessionId,
				};
				if (chatHistory[0].label === "Today") {
					setchatHistory((prevChats) => {
						const updatedChat = [...prevChats];
						updatedChat[0].entries.unshift(newChat);
						return updatedChat;
					});
				} else {
					const newToday = {
						label: "Today",
						entries: [newChat],
					};
					setchatHistory((prevChats) => {
						const updatedChat = [...prevChats];
						updatedChat.unshift(newToday);
						return updatedChat;
					});
				}
			}
			setFormData((prevState) => ({ ...prevState, query: "" }));
		} else {
			setError((prev) => true);
		}
		return true;
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setError((prev) => false);
		const { name, value } = event.target;
		if (value) {
			setSubmitBtn(true);
		} else {
			setSubmitBtn(false);
		}
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleNewChat = () => {
		if (type === "doc") {
			navigate("/features/document-generation");
		} else {
			navigate("/features/chat");
		}
	};

	return (
		<Layout className="w-full h-[100vh] overflow-hidden relative z-0 dark:bg-darkBg">
			<SideBar
				newChat={handleNewChat}
				chatHistory={chatHistory}
				type={type}
			/>
			<Layout>
				<Content>
					<Row className="w-full h-full relative overflow-x-hidden ">
						<Row
							className={`w-full h-full flex justify-center align-middle items-center  ${
								!newChat ? "hidden" : ""
							}`}
						>
							<Row
								className={`bg-[#F8F8F8] sm:w-[31.5rem] w-full sm:mx-0 mx-2 p-10 border-2 border-solid border-[#C1C1C1] rounded-2xl shadow-md dark:bg-darkCard`}
							>
								<FileSearchOutlined
									className="pb-5 w-full flex justify-center"
									style={{
										fontSize: "5.5rem",
										color: "#C1C1C1",
									}}
								/>
								<Typography className="text-3xl leading-10 text-center text-[#787878]">
									{props.type === "doc"
										? "Unlock the truth with our legal assistant!"
										: "Chat with our Rondesse AI and unlock the truth!"}
								</Typography>
							</Row>
							<ChatBar
								class={"absolute"}
								error={error}
								submitBtn={submitBtn}
								isDisabled={isDisabled}
								handleSubmit={handleSubmit}
								formData={formData}
								handleChange={handleChange}
								queryRef={queryRef}
								newChat={newChat}
								type={type}
							/>
						</Row>
						<Row className="w-full h-full flex flex-col overflow-auto items-stretch flex-1 relative">
							<ChatScreen
								messages={messages}
								loading={loadingChat}
								loadingQuestion={loadingQuestion}
							/>
							<ChatBar
								class={"sticky"}
								error={error}
								submitBtn={submitBtn}
								isDisabled={isDisabled}
								handleSubmit={handleSubmit}
								formData={formData}
								handleChange={handleChange}
								queryRef={queryRef}
								newChat={newChat}
								type={type}
							/>
						</Row>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

export default Entry;
