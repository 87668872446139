import { SendOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space, Typography } from "antd";
import React from "react";
import { ChatBarInterface } from "./ChatBarInterface";
import TextArea from "antd/es/input/TextArea";

const ChatBar = (props: ChatBarInterface) => {
  const initialMessage = [
    {
      title: "Draft a Notice of Discovery.",
      desc: "Draft a Notice of Discovery.",
      minHeight: 100,
    },
    {
      title: "Create a document for Employment contract for a Date 4th june 2024.",
      desc: "Create a document for Employment contract for a Date 4th june 2024.",
      minHeight: 100,
    },
    {
      title: "I'm a tenant, and my landlord is refusing to return my security deposit. What are my options?",
      desc: "I'm a tenant, and my landlord is refusing to return my security deposit. What are my options?",
      minHeight: 100,
    },
    {
      title: "What are my rights if I’m arrested?",
      desc: "What are my rights if I’m arrested?",
      minHeight: 100,
    },
    {
      title: "List of lawyers with experience less than 2 years?",
      desc: "List of lawyers with experience less than 2 years?",
      minHeight: 100,
    },
  ];
  return (
    <>
      <form
        className={`${props.class} bottom-0 p-3 w-full flex justify-center bg-transparent`}
      >
        <Col className="w-full md:w-auto">

          <Space size={14} className={`${!props.newChat ? "hidden" : ""
            }`}>
            {initialMessage?.map((msg, index) => (
              <div
                key={index}
                style={{
                  maxWidth: "250px",
                  border: "1px solid #C1C1C1",
                  minHeight: msg?.minHeight,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center"
                }}
                className="cursor-pointer border bg-white dark:bg-darkCard p-2 rounded-md max-w-sm text-center hover:bg-gray-200 dark:hover:bg-gray-800"
                onClick={(e) => {
                  props.handleSubmit(e, msg?.desc);
                }}
              >
                <Typography>{msg.title}</Typography>
              </div>
            ))}
          </Space>

          <Row
            style={{ width: "100%" }}
            className="w-full min-w-full md:min-w-[768px] md:w-3/4 xl:w-1/2 my-5 flex flex-row rounded-xl shadow-lg bg-white justify-end items-center dark:bg-darkCard relative"
          >
          <TextArea
            placeholder="Send a message..."
            autoSize={{ minRows: 1, maxRows: 6 }}
            name="query"
            className={
              "h-auto py-4 flex-1 border-0 bg-transparent focus:ring-0 focus-visible:ring-0 border-transparent focus:outline-none focus-visible:outline-none focus-visible:border-transparent focus:border-transparent hover:border-none" +
              (props.error ? " border-2 border-red-200" : "")
            }
            value={props.formData.query}
            autoComplete="off"
            onChange={props.handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                props.handleSubmit(e);
              }
            }}
            ref={props.queryRef}
          />
          <Button
            htmlType="submit"
            className={`text-lg border-0 p-2 mr-2 flex justify-center items-center ${props.submitBtn
              ? "bg-primary text-white cursor-pointer"
              : "text-primary"
              }`}
            disabled={props.isDisabled}
            onClick={(e) => {
              if (props.formData !== "")
                props.handleSubmit(e)
              else
                props.error = true
            }}
          >
            <SendOutlined />
          </Button>
        </Row>

      </Col>
    </form >
    </>
  );
};

export default ChatBar;
