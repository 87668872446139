import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const localStorageTheme = localStorage.getItem("theme")
	? JSON.parse(localStorage.getItem("theme") || "{}")
	: {};
export interface ThemeState {
	theme: string;
}
const initialState: ThemeState = {
	theme: localStorageTheme.theme || "light",
};

const ThemeSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {
		toggleTheme: (state, action: PayloadAction<string>) => {
			state.theme = action.payload;
			localStorage.setItem(
				"theme",
				JSON.stringify({ theme: action.payload }),
			);
		},
	},
});

export const { toggleTheme } = ThemeSlice.actions;
export default ThemeSlice.reducer;
