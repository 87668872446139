const APP_CONSTANTS: any = {
  SEARCH_TYPE: {
    1: "Rondesse AI",
    2: "Web",
  },
  SEARCH_ERROR_MESSAGE: "Please enter your query",
  AUTH_ERROR: {
    EMAIL: "Please enter your email address",
    PASSWORD: "Please enter your Password",
    EMAIL_VERIFY:
      "Your email address has not been verified. We have resent you the verification email",
    BIRTH_DATE: "Please enter your date of birth",
    COUNTRY_CODE: "Please enter your country code",
    PHONE_NUMBER: "Please enter your phone number",
    FIRST_NAME: "Please enter your first name",
    LAST_NAME: "Please enter your last name",
    RESET_PASSWORD: "The password and confirm password fields do not match.",
  },
  AUTH_SUCCESS: {
    EMAIL_VERIFY: "Your email address has been successfully verified!",
    REGISTER:
      "Congratulations! Your registration was successful. Please check your email for a verification link to complete the registration process.",
    FORGOT_PASSWORD:
      "Your password reset link has been sent to your email address!",
    RESET_PASSWORD: "Your password has been successfully reset!",
  },
};

export default APP_CONSTANTS;
