import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

const UploadSuccess = (props: any) => {
  return (
		<div className="flex flex-col items-center  w-full h-full justify-center">
			<h1 className="dark:text-white">All Done!</h1>
			<CheckCircleTwoTone
				style={{ fontSize: "5em" }}
				twoToneColor="#52c41a"
				className="my-6"
			/>
			<h3 className="text-center dark:text-white">
				Perfect! Your file has been uploaded
			</h3>
			<Button
				onClick={() => {
					props.setCurrent(0);
				}}
				className="my-6"
			>
				Start Again
			</Button>
			<Link to="/admin">
				<Button className="!px-8 mt-4" type="primary">
					Done
				</Button>
			</Link>
		</div>
  );
};

export default UploadSuccess;
