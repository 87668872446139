import { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps, UploadFile, message } from "antd";
import APIURL from "../../utils/getApiURL";
const { Dragger } = Upload;

const FileUpload = (props: any) => {
  let uploadCount = 0;
  const uploadProps: UploadProps = {
		name: "file",
		multiple: true,
		accept: ".docx, .txt, .pdf",
		data: props.formData,
		action: APIURL + "/admin-panel/upload-file/",
		onChange(info) {
			const { status } = info.file;

			if (status === "done") {
				uploadCount++;
				if (uploadCount === info.fileList.length) {
					props.setCurrent(2);
				}
			}
		},
		onDrop(e: any) {
			console.log("Dropped files", e.dataTransfer.files);
		},
  };
  return (
		<div className="sm:h-[70vh] h-[100vh] overflow-auto w-[90%] flex items-center flex-col justify-between">
			<div className="overflow-auto h-[60vh]  md:w-[80%] w-[90%]">
				<div className="md:h-[30vh] h-[40vh]">
					<Dragger {...uploadProps}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">
							Click or drag file to this area to upload
						</p>
						<p className="ant-upload-hint">
							Support for a single or bulk upload. File format
							should of .txt
						</p>
					</Dragger>
				</div>
			</div>

			<Button
				type="dashed"
				onClick={() => {
					props.setCurrent(0);
				}}
			>
				Previous
			</Button>
		</div>
  );
};

export default FileUpload;
