import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import {
  loginFail,
  loginPending,
  loginSuccess,
} from "../../app/features/auth/AuthSlice";
import { loginUser } from "../../app/services/UserServices";
import { RootState } from "../../app/store/store";
import "./Login.css";
import APP_CONSTANTS from "../../utils/constants/app.constants";
import { LoginData } from "./LoginType";
import Logo from "../../components/Common/Logo";
import { SizeType } from "antd/es/config-provider/SizeContext";

type Props = {};

const Login = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { error, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const [size] = useState<SizeType>("large");

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const onFinish = (values: any) => {
    setLoading(true);
    const data: LoginData = {
      email: values.email,
      password: values.password,
    };

    dispatch(loginPending());

    loginUser(data).then((res: any) => {
      if (res.status === 200) {
        setLoading(false);
        dispatch(loginSuccess(res.data));
        navigate("/");
      } else {
        setLoading(false);
        dispatch(loginFail(res));
      }
    });
  };

  const handleEmailProps = () => {
    if (searchParams.has("email-verfiy")) {
      const token = searchParams.get("email-verfiy");
      if (token) {
        searchParams.delete("email-verfiy");
        console.log("setting params:", {
          searchParams: searchParams.toString(),
        });
        console.dir(searchParams.toString());
        setSearchParams(searchParams);
      }
    }
  };

  return (
		<Row
			justify="center"
			className="min-h-[calc(100vh-120px)] flex flex-col justify-center gap-4 items-center dark:bg-darkBg"
		>
			<Row className="text-center">
				<Logo />
			</Row>
			<Col md={10} xs={22}>
				<Card title="Sign In" className="card-title-center">
					{searchParams.get("email-verfiy") === "true" ? (
						<Alert
							message={APP_CONSTANTS.AUTH_SUCCESS.EMAIL_VERIFY}
							type="success"
							closable
							showIcon
							onClick={handleEmailProps}
							className="mb-3"
						/>
					) : (
						""
					)}
					{searchParams.get("email-verfiy") === "false" ? (
						<Alert
							message={APP_CONSTANTS.AUTH_ERROR.EMAIL_VERIFY}
							type="error"
							closable
							onClick={handleEmailProps}
							showIcon
							className="mb-3"
						/>
					) : (
						""
					)}
					{error ? (
						<Alert
							message={error}
							type="error"
							showIcon
							className="mb-3"
						/>
					) : (
						""
					)}
					<Form
						name="login"
						layout="vertical"
						initialValues={{ remember: true }}
						onFinish={onFinish}
						autoComplete="off"
					>
						<Form.Item
							label="Email"
							name="email"
							rules={[
								{
									required: true,
									message: APP_CONSTANTS.AUTH_ERROR.EMAIL,
								},
							]}
						>
							<Input
								id="email"
								name="email"
								type="email"
								placeholder="Email"
								prefix={
									<UserOutlined className="site-form-item-icon" />
								}
							/>
						</Form.Item>
						<Form.Item
							label="Password"
							name="password"
							rules={[
								{
									required: true,
									message: APP_CONSTANTS.AUTH_ERROR.PASSWORD,
								},
							]}
						>
							<Input.Password
								id="password"
								name="password"
								type="password"
								placeholder="Password"
								prefix={
									<LockOutlined className="site-form-item-icon" />
								}
							/>
						</Form.Item>
						<Form.Item className="mb-0 text-center flex justify-evenly">
							<Button
								type="primary"
								loading={loading}
								htmlType="submit"
								size={size}
								className="text-white bg-primary hover:bg-white hover:text-primary !px-16"
							>
								Submit
							</Button>
							<Link to={"/user/forgot-password"}>
								<Button type="link">Forgot Password</Button>
							</Link>
						</Form.Item>
					</Form>
				</Card>
			</Col>
		</Row>
  );
};

export default Login;
