import { useState } from "react";
import { Switch } from "antd";
import { IoIosSunny, IoIosMoon } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { toggleTheme } from "../../app/features/themeSlice";
import { RootState } from "../../app/store/store";

const ThemeChange = () => {
	const { theme } = useSelector((state: RootState) => state.theme);
	const dispatch = useDispatch();

	const HandleTheme = (checked: boolean) => {
		if (checked) {
			dispatch(toggleTheme("dark"));
		} else {
			dispatch(toggleTheme("light"));
		}
	};
	return (
		<>
			<Switch
				checkedChildren={<IoIosMoon />}
				unCheckedChildren={<IoIosSunny />}
				onChange={HandleTheme}
				checked={theme === "light" ? false : true}
			></Switch>
		</>
	);
};

export default ThemeChange;
