const { REACT_APP_DEV_URL, REACT_APP_PROD_URL,REACT_APP_ENV } = process.env;

const getApiUrl = () => {
  const env = REACT_APP_ENV;
  if (env === "dev") {
    return REACT_APP_DEV_URL;
  } else if (env === "production") {
    return REACT_APP_PROD_URL;
  } else {
    return "";
  }
};

const APIURL = getApiUrl();

export default APIURL;