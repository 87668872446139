

export default function UserDetail() {
  let UserDetail: any;
  const localStorageUser = localStorage.getItem("user");

  if (localStorageUser) {
    try {
      UserDetail = JSON.parse(localStorageUser);
    } catch (error) {
      console.error("Error parsing localStorageUser:", error);
      UserDetail = {};
    }
  } else {
    UserDetail = {};
  }
  return UserDetail;
  
};
