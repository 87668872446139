import React from 'react'
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { verifyUser } from '../../app/services/UserServices';
import { RootState } from "../../app/store/store";
import { EmailVerifyData } from './EmailVerfiyType';

type Props = {}

const EmailVerification = (props: Props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const handleVerfication = () => {
    const emailVerify: EmailVerifyData = {
      email : searchParams.get("email"),
      session_token : searchParams.get("token")
    }
    verifyUser(emailVerify).then((res: any) => {
      if (res.status === 200) {
        console.log("Success response : ", res.data)
        navigate("/user/login?email-verfiy=true");
      } else {
        console.log("Error response : ", res)
        navigate("/user/login?email-verfiy=false");
      }
    });

    return true;
  }
  return (
    <>
      {handleVerfication()}
    </>
  )
}

export default EmailVerification