import axios from "axios";
import APIURL from "./getApiURL";

const axiosInstance = axios.create({
	baseURL: APIURL, // Set your API base URL
	headers: {
		// Set default headers if needed
		"Content-Type": "application/json",
	},
});

export default axiosInstance;
