import { MailOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { forgotPassword } from "../../app/services/UserServices";
import { RootState } from "../../app/store/store";
import Logo from "../../components/Common/Logo";
import APP_CONSTANTS from "../../utils/constants/app.constants";
import { ForgotPasswordData } from "./ForgotPasswordType";

type Props = {};

const ForgotPassword = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState<any>({});
  const [response, setResponse] = useState<boolean>(false);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [size] = useState<SizeType>("large");

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (values: any) => {
    setLoading(true);
    const data: ForgotPasswordData = {
      email: values.email,
    };

    forgotPassword(data).then((res: any) => {
      if (res.status === 202) {
        setError("");
        setResponse(res);
        setLoading(false);
        form.resetFields();
      } else {
        setError(res);
        setLoading(false);
        console.log("Error response : ", res);
      }
    });
  };
  return (
    <Row
      justify="center"
      className="min-h-[calc(100vh-120px)] flex flex-col justify-center gap-4 items-center"
    >
      <Row className="text-center">
        <Logo />
      </Row>
      <Col md={10} xs={22}>
        <Card title="Forgot Password" className="card-title-center">
          {errors.non_field_errors &&
            errors.non_field_errors.map((error: any, index: any) => (
              <Alert
                key={index}
                message={error}
                type="error"
                showIcon
                className="mb-3"
              />
            ))}
          {response && (
            <Alert
              message={APP_CONSTANTS.AUTH_SUCCESS.FORGOT_PASSWORD}
              type="success"
              closable
              showIcon
              className="mb-3"
            />
          )}
          <Form
            form={form}
            layout="vertical"
            name="register"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              help={errors.email}
              validateStatus={errors.email ? "error" : ""}
              rules={[
                { required: true, message: APP_CONSTANTS.AUTH_ERROR.EMAIL },
              ]}
            >
              <Input
                placeholder="Email"
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item className="mb-0 text-center">
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                size={size}
                className="text-white bg-primary hover:bg-white hover:text-primary !px-8"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
