import { Link } from "react-router-dom";
import SideLogo from "../../assets/logo/side_logo.png";

const OnlyLogo = (props: any) => {
	return (
		<Link to={"/"}>
			<div className={`sm:w-[16em] sm:h-[10em] w-[10em] h-[6em]`}>
				<img
					src={SideLogo}
					alt="Rondesse AI"
					className="w-full h-full object-cover"
				/>
			</div>
		</Link>
	);
};

export default OnlyLogo;
