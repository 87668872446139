import axios from "axios";
import APIURL from "../../utils/getApiURL";
import axiosInstance from "../../utils/axiosInstance";

export const documentHistory = async () => {
	try {
		const access = localStorage.getItem("accessToken")
			? (localStorage.getItem("accessToken") as string)
			: "";
		let res = await axiosInstance.post(APIURL + "/doc/history/", {
			headers: { Authorization: `Bearer ${access}` },
		});
		// res = res;
		// console.log("inside try block response :", res.data);
		return res;
	} catch (error: any) {
		let msg = error.response.data.message;
		console.log("Inside catch block error :", msg);
		return Promise.reject(error);
	}
};
export const docChatHistoryApi = async (data: string | undefined) => {
	try {
		const access = localStorage.getItem("accessToken")
			? (localStorage.getItem("accessToken") as string)
			: "";
		let res = await axiosInstance.post(
			APIURL + "/doc/chat/",
			{
				id: data,
			},
			{
				headers: { Authorization: `Bearer ${access}` },
			},
		);
		// res = res;
		// console.log("inside try block response :", res.data);
		return res;
	} catch (error: any) {
		let msg = error.response.data.message;
		console.log("Inside catch block error :", msg);
		return Promise.reject(error);
	}
};

export const docApiNew = async (data: any) => {
	try {
		console.log(data);
		const access = localStorage.getItem("accessToken")
			? (localStorage.getItem("accessToken") as string)
			: "";
		let res = await axiosInstance.post(
			APIURL + "/doc/new/",
			{
				query: data.query,
				session: data.session,
			},
			{ headers: { Authorization: `Bearer ${access}` } },
		);
		res = res;
		console.log("inside try block response :", res.data);
		return res;
	} catch (error: any) {
		console.dir(error);
		let msg = error.response.data.message;
		console.log("Inside catch block error :", msg);
		return Promise.reject(error);
	}
};

export const downloadDoc = async (data: any) => {
	try {
		const access = localStorage.getItem("accessToken")
			? (localStorage.getItem("accessToken") as string)
			: "";
		let res = await axiosInstance.post(
			APIURL + "/doc/download/",
			{
				htmlDoc: data,
			},
			{ headers: { Authorization: `Bearer ${access}` } },
		);
		res = res;
		console.log("inside try block response :", res.data);
		return res;
	} catch (error: any) {
		console.dir(error);
		let msg = error.response.data.message;
		console.log("Inside catch block error :", msg);
		return Promise.reject(error);
	}
};
