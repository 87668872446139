import React from "react";
import { Skeleton } from "antd";

const HistorySkeleton = () => {
	return (
		<div className="w-full flex flex-col pl-4">
			<Skeleton.Input className="mb-4" />

			{Array.from({ length: 4 }).map((_, i) => (
				<div key={`ldkfjsdd${i}`} className="w-[90%] mt-4">
					<Skeleton.Input active block size="small" />
				</div>
			))}
		</div>
	);
};

export default HistorySkeleton;
