import React from 'react'


const Footer = (props: any) => {
	return (
		<div
			className={`mt-auto text-center w-full h-[5vh]  ${
				props.color
					? `text-${props.color}`
					: "dark:bg-darkBg dark:text-white"
			}`}
		>
			@All right reserved {new Date().getFullYear()}
		</div>
	);
};

export default Footer