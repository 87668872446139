import axios from "axios";
import { SearchType } from "../../pages/Search/SearchType";
import { SearchType as SearchTypeNew } from "../../pages/Chat/SearchType";
import APIURL from "../../utils/getApiURL";
import axiosInstance from "../../utils/axiosInstance";

export const searchApi = async (data: SearchType) => {
	try {
		let res = await axios.get(APIURL + "/search", {
			params: {
				q: data.query,
				type: data.type,
				s: data.session,
				cr: data.cr,
			},
		});
		// res = res;
		// console.log("inside try block response :", res.data);
		return res;
	} catch (error: any) {
		let msg = error.response.data.message;
		console.log("Inside catch block error :", msg);
		return msg;
	}
};

export const refreshToken = async () => {
	try {
		const refreshToken = localStorage.getItem("refreshToken");
		const response = await axios.post(APIURL + "/auth/user/token/refresh", {
			refresh: refreshToken,
		});
		const { access } = response.data;
		localStorage.setItem("accessToken", access);
		return access;
	} catch (error) {
		// Handle token refresh error
		console.log("Token refresh error:", error);
		throw error;
	}
};

axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				const access = await refreshToken();
				axios.defaults.headers.common[
					"Authorization"
				] = `Bearer ${access}`;
				originalRequest.headers["Authorization"] = `Bearer ${access}`;
				return axios(originalRequest);
			} catch (refreshError) {
				// Handle token refresh error
				console.log("Token refresh error:", refreshError);
				throw refreshError;
			}
		}
		return Promise.reject(error);
	},
);

export const searchApiNew = async (data: SearchTypeNew) => {
	try {
		const access = localStorage.getItem("accessToken")
			? (localStorage.getItem("accessToken") as string)
			: "";
		let res = await axiosInstance.post(
			APIURL + "/search/new/",
			{
				query: data.query,
				session: data.session,
			},
			{ headers: { Authorization: `Bearer ${access}` } },
		);
		// res = res;
		// console.log("inside try block response :", res.data);
		return res;
	} catch (error: any) {
		let msg = error.response.data.message;
		console.log("Inside catch block error :", msg);
		return Promise.reject(error);
	}
};

export const searchHistory = async () => {
	try {
		const access = localStorage.getItem("accessToken")
			? (localStorage.getItem("accessToken") as string)
			: "";
		let res = await axiosInstance.post(APIURL + "/search/history/", {
			headers: { Authorization: `Bearer ${access}` },
		});
		// res = res;
		// console.log("inside try block response :", res.data);
		return res;
	} catch (error: any) {
		let msg = error.response.data.message;
		console.log("Inside catch block error :", msg);
		return Promise.reject(error);
	}
};

export const chatHistoryApi = async (data: string | undefined) => {
	try {
		const access = localStorage.getItem("accessToken")
			? (localStorage.getItem("accessToken") as string)
			: "";
		let res = await axiosInstance.post(
			APIURL + "/search/chat/",
			{
				id: data,
			},
			{
				headers: { Authorization: `Bearer ${access}` },
			},
		);
		// res = res;
		// console.log("inside try block response :", res.data);
		return res;
	} catch (error: any) {
		let msg = error.response.data.message;
		console.log("Inside catch block error :", msg);
		return Promise.reject(error);
	}
};

export const chatHistoryDelete = async (chatId: string) => {
	try {
		const access = localStorage.getItem("accessToken")
			? (localStorage.getItem("accessToken") as string)
			: "";
		let res = await axiosInstance.delete(
			APIURL + `/search/delete/${chatId}/`,
			{
				headers: { Authorization: `Bearer ${access}` },
			}
		)
		return res;
	} catch (error: any) {
		console.log("Inside catch block error: ", error);
		return Promise.reject(error);
	}
}