import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../../app/store/store";
import { Button } from "antd";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import { logOutUser } from "../../app/services/UserServices";
import { logOutUserSuccess } from "../../app/features/auth/AuthSlice";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
// import AddToIphone from "../Common/AddToIphone";
import Logo from "../../components/Common/Logo";
import ThemeChange from "../Common/ThemeChange";

type Props = {};

const Header = (props: Props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isAuthenticated, user } = useSelector(
		(state: RootState) => state.auth
	);
	const { is_admin } = user;
	const [size] = useState<SizeType>("middle");
	const [sideBar, setSideBar] = useState<Boolean>(false);
	const sideNavRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Add event listener to the document object
		document.addEventListener("mousedown", handleClickOutside);

		// Remove event listener when the component unmounts
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleLogout = (e: any) => {
		e.preventDefault();
		logOutUser();
		dispatch(logOutUserSuccess());
		navigate("/");
	};

	const handleNav = () => {
		setSideBar(!sideBar);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			sideNavRef.current &&
			!sideNavRef.current.contains(event.target as Node)
		) {
			// Clicked outside the side navigation bar, close it
			setSideBar(false);
		}
	};
	return (
		<div className="fixed w-full z-10 px-2">
			<nav className="justify-between px-8 hidden md:flex items-center">
				<div className="text-center">
					<Logo type="nav" />
				</div>
				<ul className="flex list-none mb-2 items-center">
					<li className="mr-2">
						<ThemeChange />
					</li>
					{isAuthenticated ? (
						<>
							<li className="mr-2">
								<Link to={"/"}>
									<Button className="!px-8 !text-base" size={size}>
										Home
									</Button>
								</Link>
							</li>
							{is_admin ? (
								<li className="mr-2">
									<Link to={"/admin"}>
										<Button className="!px-8 !text-base" size={size}>
											Admin Panel
										</Button>
									</Link>
								</li>
							) : (
								""
							)}
							<li>
								<Button
									type="primary"
									className="!px-8"
									size={size}
									onClick={handleLogout}
								>
									Logout
								</Button>
							</li>
						</>
					) : (
						<>
							{/* <li className="w-full">
								<AddToIphone />
							</li> */}
							<li className="mr-2">
								<Link to={"/user/registration"}>
									<Button className="!px-8 !text-base" size={size}>
										Sign Up
									</Button>
								</Link>
							</li>
							<li className="mr-2">
								<Link to={"/user/login"}>
									<Button
										type="primary"
										className="!px-8 !text-base"
										size={size}
									>
										Sign In
									</Button>
								</Link>
							</li>
						</>
					)}
				</ul>
			</nav>

			<nav className="flex justify-between items-center p-4 md:hidden">
				<AiOutlineMenu
					className={"w-6 h-6 dark:text-white"}
					onClick={handleNav}
				/>
				<div className="text-center">
					<Logo type="nav" />
				</div>
				<ThemeChange />
				<div
					className={
						" dark:bg-darkBg pl-2 fixed top-0 left-0 z-40 w-64 h-screen shadow-lg bg-white md:hidden transition-transform duration-500 ease-in-out " +
						(!sideBar ? " transform -translate-x-full" : "transform translate-x-0")
					}
					ref={sideNavRef}
				>
					<AiOutlineClose
						className={"w-6 h-6 flex justify-start px-6 py-2"}
						onClick={handleNav}
					/>
					<ul className="flex flex-col justify-center list-none pt-8">
						{isAuthenticated ? (
							<>
								<li className="mb-2">
									<Link to={"/"}>
										<Button className="!px-8 !text-base" size={size}>
											Home
										</Button>
									</Link>
								</li>
								{is_admin ? (
									<li className="mr-2">
										<Link to={"/admin"}>
											<Button
												className="!px-8 !text-base mb-2"
												size={size}
											>
												Admin Panel
											</Button>
										</Link>
									</li>
								) : (
									""
								)}
								<li>
									<Button
										className="text-white bg-primary hover:bg-white hover:text-primary !px-8"
										size={size}
										onClick={handleLogout}
									>
										Logout
									</Button>
								</li>
							</>
						) : (
							<>
								{/* <li className="mb-2">
								<AddToIphone />
							</li> */}
								<li className="mb-2">
									<Link to={"/user/registration"}>
										<Button
											className="text-primary text-lg"
											type="link"
										>
											Sign Up
										</Button>
									</Link>
								</li>
								<li>
									<Link to={"/user/login"}>
										<Button
											type="link"
											className="text-primary text-lg"
										>
											Sign In
										</Button>
									</Link>
								</li>
							</>
						)}
					</ul>
				</div>
			</nav>

		</div>

	);
};

export default Header;
