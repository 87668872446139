import { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logOutUserSuccess } from "../../app/features/auth/AuthSlice";
import { logOutUser } from "../../app/services/UserServices";
import { MenuOutlined, UploadOutlined, HomeOutlined } from "@ant-design/icons";
import { Drawer, Button } from "antd";

const ResponsiveMenu = () => {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const linkStyler = (e: any) => {
		const { isActive } = e;
		return isActive
			? "flex items-center h-[7vh] border-primary border-[1px] border-solid rounded-lg pl-2 dark:border-white"
			: "flex items-center h-[7vh] pl-2";
	};
	const handleLogout = (e: any) => {
		e.preventDefault();
		logOutUser();
		dispatch(logOutUserSuccess());
		navigate("/");
	};
	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};
	return (
		<>
			<div className="w-[100%] md:hidden">
				<div className="p-8">
					<MenuOutlined
						onClick={showDrawer}
						className="cursor-pointer dark:text-white"
					/>
				</div>
			</div>
			<Drawer
				title=" Administrator Panel"
				placement="left"
				onClose={onClose}
				open={open}
				extra={
					<Button type="primary" onClick={handleLogout}>
						LogOut
					</Button>
				}
			>
				<NavLink
					to={"/admin"}
					style={{ textDecoration: "none" }}
					onClick={onClose}
					className={linkStyler}
					end
				>
					<HomeOutlined className="text-[#474F97] dark:text-white" />
					<p className="text-primary ml-8 font-sm dark:text-white">
						Home
					</p>
				</NavLink>
				<NavLink
					to={"/admin/file-upload"}
					style={{ textDecoration: "none" }}
					className={linkStyler}
					onClick={onClose}
					end
				>
					<UploadOutlined className="text-[#474F97] dark:text-white" />
					<p className="text-primary ml-8 font-sm dark:text-white">
						File Upload
					</p>
				</NavLink>
			</Drawer>
		</>
	);
};

export default ResponsiveMenu;
