import React, { useState } from "react";
import { Button, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as VoiceIcon } from "../../assets/icons/Voice.svg";
import APP_CONSTANTS from "../../utils/constants/app.constants";
import PlateFormDropdown from "../../components/Dropdowns/Plateform.Dropdown";
import Logo from "../../components/Common/Logo";
import Hero from "../../components/Home/Hero";

type Props = {};

const Home = (props: Props) => {
	const [size] = useState<SizeType>("middle");
	const navigate = useNavigate();
	const [error, setError] = useState<boolean>(false);
	const errorMsg = APP_CONSTANTS.SEARCH_ERROR_MESSAGE;
	// const provinceData = APP_CONSTANTS.SEARCH_TYPE;
	const provinceDataDefault = "1";

	const [formData, setFormData] = useState<{ query: string; type: string }>({
		query: "",
		type: provinceDataDefault,
	});

	const createRedirectUrl = (cr?: boolean | null) => {
		if (formData.query !== "") {
			setError((prev) => false);
			if (cr) {
				navigate(
					"/search?q=" +
						formData.query +
						"&type=" +
						formData.type +
						"&cr=true",
				);
			} else {
				navigate(
					"/search?q=" + formData.query + "&type=" + formData.type,
				);
			}
		} else {
			setError((prev) => true);
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		createRedirectUrl();
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setError((prev) => false);
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const handlePlateformChange = (value: string) => {
		setFormData((prevState) => ({ ...prevState, type: value }));
	};

	const handlePlateformClick = () => {
		createRedirectUrl();
	};

	const handleCRClick = () => {
		createRedirectUrl(true);
	};

	return <Hero />;
};

export default Home;
