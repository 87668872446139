import { LoginData } from "../../pages/Login/LoginType";
import axios from "axios";
import { RegisterData } from "../../pages/Registration/RegisterType";
import { EmailVerifyData } from "../../pages/EmailVerification/EmailVerfiyType";
import APIURL from "../../utils/getApiURL";
import { ForgotPasswordData } from "../../pages/ForgotPassword/ForgotPasswordType";
import { ResetPasswordData } from "../../pages/ResetPassword/ResetPasswordType";

export const loginUser = async (data: LoginData) => {
  try {
    let res = await axios.post(APIURL + "/auth/user/login", data);
    // res = res;
    // console.log("inside try block response :", res.data);
    return res;
  } catch (error: any) {
    let msg = error.response.data.message;
    console.log("Inside catch block error :", msg);
    return msg;
  }
};

export const registerUser = async (data: RegisterData) => {
  try {
    let res = await axios.post(APIURL + "/auth/user/register", data);
    // res = res;
    // console.log("Inside try block response :", res);
    return res;
  } catch (error: any) {
    let msg = error.response.data;
    // try to check error formate or discuss with mayank about error massage
    console.log("Inside catch block error :", msg);
    return msg;
  }
};

export const verifyUser = async (data: EmailVerifyData) => {
  try {
    let res = await axios.post(APIURL + "/auth/user/email/verify", data);
    // console.log("Inside try block response :", res);
    return res;
  } catch (error: any) {
    let msg = error.response.data;
    console.log("Inside catch block error :", msg);
    return msg;
  }
};

export const forgotPassword = async (data: ForgotPasswordData) => {
  try {
    let res = await axios.post(APIURL + "/auth/user/forgot-password", data);
    // console.log("Inside try block response :", res);
    return res;
  } catch (error: any) {
    let msg = error.response.data;
    console.log("Inside catch block error :", msg);
    return msg;
  }
};
export const resetPassword = async (data: ResetPasswordData) => {
  try {
    let res = await axios.post(APIURL + "/auth/user/forgot-password", data);
    // console.log("Inside try block response :", res);
    return res;
  } catch (error: any) {
    let msg = error.response.data;
    console.log("Inside catch block error :", msg);
    return msg;
  }
};
export const logOutUser = async () => {
  localStorage.clear();
};
