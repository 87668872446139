import React from "react";
import Footer from "../../components/Footer/Footer";
import OnlyLogo from "../../components/Common/OnlyLogo";
import Chat from "../../assets/PNG/Chat.png";
import Document from "../../assets/PNG/Document.png";
import { Link } from "react-router-dom";

const Features = () => {
	return (
		<div className="w-full h-screen bg-primary flex flex-col items-center justify-between sm:pt-8 pt-16 dark:bg-darkBg">
			<div>
				<OnlyLogo size="6em"></OnlyLogo>
			</div>
			<div className="xl:w-[50%] lg:w-[60%] md:w-[70%] w-[80%] flex flex-col items-center">
				<h1 className="text-white text-center text-xl sm:text-4xl">
					What are you looking for?
				</h1>
				<div className="lg:w-[80%] w-[100%] flex justify-center sm:mt-16 mt-8">
					<Link to={"/features/chat"} className="no-underline">
						<div className="md:h-[40vmin] md:w-[30vmin] h-[45vmin] w-[35vmin] bg-white rounded-lg flex flex-col py-8 justify-between items-center dark:bg-darkCard">
							<div className="w-[100%] h-[60%]">
								<img
									src={Chat}
									alt=""
									className="w-full h-full object-contain"
								/>
							</div>
							<h3 className="text-primary sm:text-lg text-sm dark:text-darkSecondaryText">
								Legal Chatbot
							</h3>
						</div>
					</Link>
					
				</div>
			</div>
			<div>
				<Footer color="white"></Footer>
			</div>
		</div>
	);
};

export default Features;
