import { useState, useEffect } from "react";
import { Select,Radio, Form, Button, Typography ,notification} from "antd";
import AddFolder from "./AddFolder";
import { fetchFoldersAsyncRequest } from "../../app/services/AdminServices";

type Ioptions = {
	value: string;
}[];
const SelectSites = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false);
	// const [value, setValue] = useState<boolean>(props.formData?.site || false);
	// const [siteValue, setSiteValue] = useState<boolean>(false);
	const [options, setOptions] = useState<Ioptions | undefined>(undefined);
	// const [apiCall, setApicall] = useState<boolean>(false);
	// console.log(value)
	// const getOptions = async () => {
	// 	setLoading(true);
	// 	let { data, error } = await fetchFoldersAsyncRequest();
	// 	if (error) {
	// 		alert(error);
	// 	} else {
	// 		setOptions(data);
	// 	}
	// 	setLoading(false);
	// };

	// useEffect(() => {
	// 	getOptions();
	// 	console.log("hello");
	// }, [apiCall]);

	const handleRadioChange = (e: any) => {
		if (e.target.value === true) {
			notification.info({
				message: 'Document Format Selected',
				description: 'Select the option if the document should be considered for the document generation module.',
			});
	};
	}
	return (
		<Form
			labelCol={{ span: 8 }}
			style={{ width: "100%",}}
			wrapperCol={{ span: 16 }}
			onFinish={(e: any) => {
				props.setCurrent(1);
		props.setFormData({ ...e, site: e.site || false });

		
			}}
			initialValues={{ site: false }} 
		>
			<Form.Item
				label="Document Format"
				name="site"
				valuePropName="checked"
				rules={[
					{
						required: false,
						message: "Select the option if the document should be considered for the document generation module.",
					},
				]}	
			>
			
			<Radio.Group onChange={handleRadioChange}>
					<Radio value={true} ></Radio>
				</Radio.Group>
         
         
       
			</Form.Item>

			<Form.Item wrapperCol={{ offset: 11, span: 16 }}>
				{/* <AddFolder
					setApicall={setApicall}
					folderNameLoading={loading}
				></AddFolder> */}

				<Button
					type="primary"
					className="!px-8"
					htmlType="submit"
					// disabled={loading}
				>
					Next
				</Button>
			</Form.Item>
		</Form>
	);
};


export default SelectSites
