// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-card-head{
    /* background-color: aliceblue !important; */
    /* font-size: 20px !important; */
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,gCAAgC;AACpC","sourcesContent":[".ant-card-head{\n    /* background-color: aliceblue !important; */\n    /* font-size: 20px !important; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
