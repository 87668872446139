import React from "react";
import Logo from "../../components/Common/Logo";

const AdminPage = () => {
  return (
		<div className="md:w-[70%] w-[100%] md:p-[10vmin] p-0 flex flex-col items-center justify-between dark:bg-darkBg">
			<Logo />
			<h1 className="text-2xl mt-4 dark:text-white">
				Administrator Panel
			</h1>
		</div>
  );
};

export default AdminPage;
