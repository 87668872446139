import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { checkAuth } from "../../../utils/checkAuth";

const localStorageUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user") || "{}")
  : {};
const localStorageAccess = localStorage.getItem("accessToken");
const localStorageRefresh = localStorage.getItem("refreshToken");
export interface AuthState {
  isLoading: boolean;
  emailVerify: boolean;
  error: string;
  isAuthenticated: boolean;
  access: any;
  refresh: any;
  user: any;
}

const initialState: AuthState = {
  isLoading: false,
  emailVerify: false,
  error: "",
  isAuthenticated: checkAuth() ? true : false,
  access: localStorageAccess,
  refresh: localStorageRefresh,
  user: localStorageUser,
};

const AuthSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		loginPending: (state) => {
			state.isLoading = true;
		},
		loginSuccess: (state, action: PayloadAction<AuthState>) => {
			state.isLoading = false;
			state.isAuthenticated = true;
			localStorage.setItem("accessToken", action.payload.access);
			state.access = action.payload.access;
			localStorage.setItem("refreshToken", action.payload.refresh);
			state.refresh = action.payload.refresh;
			state.user = action.payload.user;
			localStorage.setItem(
				"user",
				action.payload.user ? JSON.stringify(action.payload.user) : "",
			);
			state.error = "";
		},
		loginFail: (state, action: PayloadAction<string>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
		emailVerifySuccess: (state) => {
			state.emailVerify = true;
		},
		emailVerifyFail: (state) => {
			state.emailVerify = false;
		},
		logOutUserSuccess: (state) => {
			state.isLoading = false;
			state.emailVerify = false;
			state.error = "";
			state.isAuthenticated = false;
			state.access = "";
			state.refresh = "";
			state.user = {};
		},
	},
});

// Action creators are generated for each case reducer function
export const { loginPending, loginSuccess, loginFail, logOutUserSuccess } =
  AuthSlice.actions;

export default AuthSlice.reducer;
