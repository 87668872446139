import { Link } from "react-router-dom";
import SideLogo from "../../assets/logo/side_logo.png";

const Logo = (props: any) => {
	return (
		<div
			className={`${props.type === "nav" ? "items-center" : "items-center"
				} flex justify-center w-full h-full`}
		>
			<Link to={"/"}>
				<div
					className={
						`${props.size === "small" ? "" : "bg-primary "}
						${props.type === "nav"
							? "items-center md:w-[4em] md:h-[6em] h-[6em]"
							: "items-center lg:w-[5em] lg:h-[5em] h-[3em]"
						} 
					h-[4em] flex`}
				>
					<div className={`${props.type === "nav" ? "h-[70%]" : ""}`}>
						<img
							alt="sidebar-logo"
							src={SideLogo}
							className={` w-[100%] h-[100%] object-cover`}
						/>
					</div>
				</div>
			</Link>
			<Link
				to={"/"}
				className="no-underline flex justify-center items-end"
			>
				<div
					className={`${props.size === "small"
							? "text-3xl"
							: "lg:text-5xl text-3xl"
						} ${props.color === "white" ? "text-white" : "text-primary"
						} font-proximanova gap-3 ml-2 flex `}
				>
					<h2
						className={`${props.size === "small"
								? "lg:text-2xl text-xl"
								: "lg:text-3xl text-xl"
							} font-bold m-0 mb-2 dark:text-white`}
					>
						Rondesse &nbsp;
						<span>AI</span>
					</h2>
				</div>
			</Link>
		</div>
	);
};

export default Logo;
