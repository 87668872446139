import React from "react";

//functions
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutUserSuccess } from "../../app/features/auth/AuthSlice";
import { logOutUser } from "../../app/services/UserServices";
//components
import Logo from "../Common/Logo";
import {
	LogoutOutlined,
	UploadOutlined,
	HomeOutlined,
} from "@ant-design/icons";
import { Button, Space } from "antd";
const SideBar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = (e: any) => {
		e.preventDefault();
		logOutUser();
		dispatch(logOutUserSuccess());
		navigate("/");
	};
	const linkStyler = (e: any) => {
		let { isActive } = e;

		return isActive
			? "flex items-center h-[5vh] border-white border-[1px] border-solid rounded-lg pl-2 mt-8 "
			: "flex items-center  pl-2 mt-8 h-[5vh] hover:bg-[#0001] rounded-lg";
	};
	return (
		<>
			<div className="lg:w-[22%] h-[100vh] bg-primary w-[30%] py-[2em] hidden absolute top-0 left-[-100%] md:flex md:static flex-col justify-between dark:bg-darkCard">
				<div className="flex flex-col px-8">
					<div className="w-fit">
						<Logo size={"small"} color="white"></Logo>
					</div>
				</div>

				<div className="w-full h-[60%] flex flex-col px-8">
					<h3 className="text-white font-lg text-xs ml-2 uppercase tracking-widest">
						Administrator Panel
					</h3>
					<NavLink
						end
						to={"/admin"}
						style={{ textDecoration: "none" }}
						className={linkStyler}
					>
						<HomeOutlined style={{ color: "#fff" }} />
						<p className="text-white ml-8 font-sm">Home</p>
					</NavLink>
					<NavLink
						end
						to={"/admin/file-upload"}
						className={linkStyler}
						style={{ textDecoration: "none" }}
					>
						<UploadOutlined style={{ color: "#fff" }} />
						<p className="text-white ml-8 font-sm">File Upload</p>
					</NavLink>
				</div>

				<div className="px-8">
					<Button icon={<LogoutOutlined />} onClick={handleLogout}>
						Log Out
					</Button>
				</div>
			</div>
		</>
	);
};

export default SideBar;
