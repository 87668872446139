import { useState, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider, theme } from "antd";
import router from "./config/routes";
import { useSelector } from "react-redux";
import { RootState } from "./app/store/store";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
const { defaultAlgorithm, darkAlgorithm } = theme;
function App() {
	const { theme } = useSelector((state: RootState) => state.theme);

	useEffect(() => {
		if (theme === "light") {
			document.documentElement.classList.remove("dark");
		} else {
			document.documentElement.classList.add("dark");
		}
	}, [theme]);

	return (
		<ConfigProvider
			theme={{
				algorithm: theme === "light" ? defaultAlgorithm : darkAlgorithm,
				token: {
					colorPrimary: "#474F97",
					fontFamily: "Poppins",
					fontWeightStrong: 400,
					fontSize: 16,
				},
			}}
		>
			<RouterProvider router={router} />
			<ToastContainer />
		</ConfigProvider>
	);
}

export default App;
