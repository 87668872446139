import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { RootState } from "../../app/store/store";

/**
 * Antd layout
 */
import SideBar from "../Admin/SideBar";
import ResponsiveMenu from "../Admin/ResponsiveMenu";

const AdminLayout = () => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.auth
  );
  const { is_admin } = user;
  if (!isAuthenticated && !is_admin) {
    return <Navigate to="/user/login" />;
  }

  return (
		<div className="w-full h-[100vh] flex md:flex-row flex-col dark:bg-darkBg">
			<SideBar></SideBar>
			<ResponsiveMenu />
			<Outlet />
		</div>
  );
};

export default AdminLayout;
