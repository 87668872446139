import React, { useMemo, useState } from "react";
import { DeleteFilled, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Popover, Row, Typography } from "antd";
import Sider from "antd/es/layout/Sider";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as HomeLogo } from "../../assets/icons/Home.svg";
import { ReactComponent as ChatLogo } from "../../assets/icons/chat.svg";
import { AiOutlineMenu } from "react-icons/ai"; // Import the menu icon
import Logo from "../../components/Common/Logo";
import { SideBarInterface } from "./SideBarInterface";
import { logOutUser } from "../../app/services/UserServices";
import { logOutUserSuccess } from "../../app/features/auth/AuthSlice";
import { useDispatch } from "react-redux";
import UserDetail from "../../utils/UserDetail";
import HistorySkeleton from "./HistorySkeleton";
import { chatHistoryDelete } from "../../app/services/SearchServices";
import useLogoutCurrentUser from "../../utils/logoutCurrentUser";

const SideBar = (props: SideBarInterface) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar visibility
	const [showArrow, setShowArrow] = useState(true);
	const [arrowAtCenter, setArrowAtCenter] = useState(false);
	const mergedArrow = useMemo(() => {
		if (arrowAtCenter) return { pointAtCenter: true };
		return showArrow;
	}, [showArrow, arrowAtCenter]);
	const [open, setOpen] = useState(false);
	const [chatId, setChatId] = useState("");
	const [confirmLoading, setConfirmLoading] = useState(false);
	const { first_name, last_name } = UserDetail();
	const logoutCurrentUser = useLogoutCurrentUser();

	const hide = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleDeleteConfirm = (chatUrl: string) => {
		const extractedChatId = chatUrl.split("/");
		setChatId(extractedChatId[2]);
		setIsModalOpen(true);
	};

	const handleDelete = () => {
		setConfirmLoading(true);
		chatHistoryDelete(chatId)
			.then((res: any) => {
				setConfirmLoading(false);
				setIsModalOpen(false);
				if (props.type === "doc") navigate("/features/document-generation");
				else navigate("/features/chat");
			})
			.catch((error: any) => {
				if (error.response.status === 401) {
					logoutCurrentUser();
				}
				console.log("error from delete api: ", error);
				setConfirmLoading(false);
				setIsModalOpen(false);
			});
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleLogout = (e: any) => {
		e.preventDefault();
		logOutUser();
		dispatch(logOutUserSuccess());
		navigate("/");
	};

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
	};

	return (
		<>
			{/* Menu button for small screens */}
			<nav className="md:hidden p-2">
				<AiOutlineMenu className="text-2xl text-white" onClick={toggleSidebar} />
			</nav>

			<Sider
				className={`!min-w-[15.6rem] md:!min-w-[21.8rem] pt-3 !bg-primary dark:!bg-darkCard 
					${
					isSidebarOpen ? "absolute" : "hidden"
				} md:block `}
			>
				<div className="flex flex-col w-full h-screen">
					<div className="fixed top-0 z-10">
						<Row className="text-center px-10">
							<Logo size={"small"} color="white" />
						</Row>
						<Row className="px-5 pb-5 flex justify-between">
							<Button
								className="bg-transparent h-12 flex-1 mr-4"
								onClick={props.newChat}
							>
								<Typography className="text-white flex justify-between">
									{props.type === "doc" ? "Document" : "Chat"}
									<PlusOutlined className="" />
								</Typography>
							</Button>
							<Link to={"/"}>
								<Button
									className="flex justify-center shadow-lg rounded-xl !w-[3rem] h-[3rem] bg-transparent"
									icon={<HomeLogo className="mt-2.5 !text-white" />}
								/>
							</Link>
						</Row>
					</div>
					<div className="mt-[140px] mb-[180px] overflow-auto">
						<Row className="py-5 overflow-y-auto flex-1">
							{props.chatHistory.length === 1 &&
							props.chatHistory[0].label === "" ? (
								Array.from({ length: 2 }).map((_, i) => (
									<HistorySkeleton key={`ldkjdfdfh${i}`} />
								))
							) : props.chatHistory.length === 0 ? (
								<Typography className="text-white px-8">
									No History....
								</Typography>
							) : (
								props.chatHistory.map((menuItem: any, index: number) => (
									<div className="w-full" key={`menu-parent-index-${index}`}>
										<Typography className="px-2 text-white block">
											{menuItem.label}
										</Typography>
										<div className="py-2 pl-5">
											{menuItem.entries &&
												menuItem.entries.map((menu: any, index: number) => (
													<div
														className="flex flex-row px-3 py-2 text-white hover:bg-gray-300 hover:bg-opacity-30 rounded-md hover:text-primary my-2 cursor-pointer"
														style={{ display: "flex", alignItems: "center" }}
													>
														<Link
															to={"/features" + menu.url}
															key={`menu-child-${index}`}
															className="flex flex-row"
															style={{ flex: "1", overflow: "hidden" }}
														>
															<span>
																<ChatLogo className="mt-1" />
															</span>
															<Button
																type="link"
																className="text-white overflow-hidden"
															>
																{menu.label}
															</Button>
														</Link>
														<DeleteFilled
															className="ml-4"
															style={{ flexShrink: 0 }}
															onClick={() => handleDeleteConfirm(menu.url)}
														/>
													</div>
												))}
											<Modal
												title="Delete confirmation"
												open={isModalOpen}
												onOk={handleDelete}
												confirmLoading={confirmLoading}
												onCancel={handleCancel}
												okText="Delete"
											>
												<p>Are you sure you want to delete this chat?</p>
											</Modal>
										</div>
									</div>
								))
							)}
						</Row>
					</div>
					<div className="fixed bottom-0 pb-3 pt-2 left-4">
						
						<div className="flex flex-row justify-between py-5 px-8 ">
							<Row>
								<Typography className="bg-green-600 text-white w-10 h-10 flex justify-center items-center text-3xl mr-4">
									{first_name ? first_name.charAt(0).toUpperCase() : ""}
								</Typography>
								<Typography className="text-white text-center align-middle mt-2">
									{first_name + " " + last_name}
								</Typography>
							</Row>
							<Row>
								<Popover
									placement="rightBottom"
									content={
										<div className="px-2">
											<Button className="mb-5 border-0" onClick={handleLogout}>
												Logout
											</Button>
											<br />
											<Button className="border-0" onClick={hide}>
												Close
											</Button>
										</div>
									}
									trigger="click"
									open={open}
									onOpenChange={handleOpenChange}
								>
									<MoreOutlined className="text-white" />
								</Popover>
							</Row>
						</div>
					</div>
				</div>
				<div className="w-1 h-full bg-black absolute right-0 top-0" />
			</Sider>
		</>
	);
};

export default SideBar;
