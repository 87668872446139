import React from 'react'

type Props = {}

const AboutUs = (props: Props) => {
  return (
    <div>AboutUs</div>
  )
}

export default AboutUs