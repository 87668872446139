import React, { useState } from "react";
//antd
import { Steps, Drawer, Button } from "antd";

//other components
import SelectSites from "../../components/Admin/SelectSites";
import FileUpload from "../../components/Admin/FileUpload";
import UploadSuccess from "../../components/Admin/UploadSuccess";
const { Step } = Steps;

const steps = [
  {
    title: "Select",
    content: "First-content",
  },
  {
    title: "File Upload",
    content: "Second-content",
  },
  {
    title: "Confirmation",
    content: "Last-content",
  },
];

const items = steps.map((item) => ({ key: item.title, title: item.title }));
const AdminFileUpload = () => {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({ site: "" });

  const contentStyle: React.CSSProperties = {
    lineHeight: "260px",
    textAlign: "center",
    marginTop: 16,
  };

  return (
		<>
			<div className="md:w-[70%] w-[100%] md:p-[10vmin] h-screen p-0 flex flex-col items-center justify-between ">
				<div className="w-[80%] md:p-0 p-8">
					<Steps current={current} items={items} />
				</div>
				<div className="w-[90%] h-[90%] flex justify-center items-center flex-col">
					{current === 0 ? (
						<SelectSites
							setCurrent={setCurrent}
							setFormData={setFormData}
							formData={formData}
						/>
					) : current === 1 ? (
						<FileUpload
							setCurrent={setCurrent}
							setFormData={setFormData}
							formData={formData}
						/>
					) : (
						<UploadSuccess setCurrent={setCurrent} />
					)}
				</div>
			</div>
		</>
  );
};

export default AdminFileUpload;
