import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../components/Error/ErrorPage";

import AuthLayout from "../components/Layout/AuthLayout";
import Layout from "../components/Layout/Layout";
import AdminLayout from "../components/Layout/AdminLayout";
import AboutUs from "../pages/AboutUs/AboutUs";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Registration from "../pages/Registration/Registration";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Chat from "../pages/Chat/Chat";
import Entry from "../pages/Chat/Entry";
import AdminFileUpload from "../pages/Admin/AdminFileUpload";
import AdminError from "../components/Error/AdminError";
import AdminPage from "../pages/Admin/AdminPage";
import Features from "../pages/Features/Features";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/user/login",
				element: <Login />,
			},
			{
				path: "/user/registration",
				element: <Registration />,
			},
			{
				path: "/user/reset-password",
				element: <ResetPassword />,
			},
			{
				path: "/user/forgot-password",
				element: <ForgotPassword />,
			},
			{
				path: "/user/email-verification",
				element: <EmailVerification />,
			},
			{
				path: "/",
				element: <AuthLayout />,
				children: [
					{
						path: "/about-us",
						element: <AboutUs />,
					},
					{
						path: "/features",
						element: <Features />,
					},
					{
						path: "/features/chat",
						element: <Chat />,
					},
					{
						path: "/features/chat/:sessionId",
						element: <Entry />,
					},
					{
						path: "/features/document-generation",
						element: <Chat type="doc" />,
					},
					{
						path: "/features/document-generation/:sessionId",
						element: <Entry type="doc" />,
					},
				],
			},
		],
	},
	{
		path: "/admin",
		element: <AdminLayout />,
		errorElement: <AdminError />,
		children: [
			{
				index: true,
				element: <AdminPage />,
			},
			{
				path: "/admin/file-upload",
				element: <AdminFileUpload />,
			},
		],
	},
]);

export default router;
