import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

type Props = {};

const Layout = (props: Props) => {
	const location = useLocation();
	return (
		<>
			{location.pathname.includes("/features") ? (
				<>
					<Outlet />
				</>
			) : (
				<>
					<div className="flex flex-col min-h-[100vh] dark:bg-darkBg justify-between">
						<Header />
						<Outlet />
						<Footer />
					</div>
				</>
			)}
		</>
	);
};

export default Layout;
