import { Button } from "antd";
import React from "react";
import { InfoCircleTwoTone } from "@ant-design/icons";

const AdminError = () => {
  return (
    <div className="w-[70%] h-[100vh] p-8 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <InfoCircleTwoTone twoToneColor="red" style={{ fontSize: "4em" }} />
        <h1 className="mt-8">Something Went Wrong</h1>
        <p className="font-normal">
          Please check your internet connection and try again
        </p>
        <Button
          className="text-white bg-primary hover:bg-white hover:text-primary !px-8 mt-8"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </Button>
      </div>
    </div>
  );
};

export default AdminError;
