import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../app/store/store";

type Props = {};

const AuthLayout = (props: Props) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/user/login" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default AuthLayout;
