import { logOutUser } from "../app/services/UserServices";
import { logOutUserSuccess } from "../app/features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';

const useLogoutCurrentUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutCurrentUser = () => {
        logOutUser();
        dispatch(logOutUserSuccess());
        navigate("/");
        toast.warn("Session Expired Login Again!")
    };

    return logoutCurrentUser;
};

export default useLogoutCurrentUser;
